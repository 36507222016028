/* Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Tailwind styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CRA defaults */
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Cool bg effect */
.gradient-bg {
  background: linear-gradient(320deg, #f39d4b, #c779d0, #4bc0c8);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 12s linear infinite;
  -moz-animation: AnimationName 12s linear infinite;
  -o-animation: AnimationName 12s linear infinite;
  animation: AnimationName 12s linear infinite;

  overflow-x: hidden;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.gradient-bg::-webkit-scrollbar {
  display: none;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
